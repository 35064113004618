import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  AppHeaderComponent,
  LoadingComponent,
  ApiLoaderComponent,
  TitleControl,
  EntityFormApi,
} from "@unity/components";
import {
  CanCreate,
  CanRead,
  CanReadAll,
  CanUpdate,
  CanModuleAdmin,
} from "../services/Permissions";
import FormCreate from "../modules/FormCreate";
import ModuleFunctions from "../modules/ModuleFunctions";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

export default function FormTemplateCreate(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0); // This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState({
    owner_id: props.context.auth.contact,
    owner_uuid: props.context.auth.id,
    owner_name: props.context.auth.name,
    dynamic: false,
    fields: [],
  });
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);

  console.log("DATA: ", data);

  const tc = new TitleControl();

  let history = useHistory();

  const create = CanCreate("form-templates");
  const read = CanRead("form-templates");
  const edit = CanUpdate("form-templates");
  const admin = CanModuleAdmin("form-templates");
  const readAll = CanReadAll("form-templates");

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const formChange = (data) => {
    setData((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const handleOwnerChange = async (item) => {
    formChange({
      changed: true,
      owner_id: item.event.target.value,
      owner_uuid: item.element.props.personObj.uuid,
      owner_name: item.element.props.personObj.name,
    });
    findContactBus(item.event.target.value);
  };

  const setTitle = () => {
    tc.setControlTitle(
      <div style={{ display: "flex", alignItems: "center", gap: "1vw" }}>
        <Button
          //sx={buttonTitle()}
          onClick={() => history.push("/form-templates/index")}
          variant="contained"
          style={{ backgroundColor: "red" }}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </div>,
      "CREATE"
    );
  };

  const handleSave = async () => {
    setLoading({
      status: true,
      data: "Creating your Template, Please Wait....",
    });

    const tempData = { template: { ...data } };

    const res = await EntityFormApi.templateStore(tempData);

    if (res.success) {
      setLoading({ status: true, data: "Successfully created Template" });
      setTimeout(() => {
        history.push({
          pathname: `/form-templates/edit/${res.data._id}`,
          //pathname: `/form-templates/edit`,
          state: { hasErrors: false },
        });
        setLoading({ status: false });
      }, 3000);
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({
          status: true,
          data: "Validation Errors!, Please Wait....",
        });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/form-templates/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  useEffect(() => {
    setTitle();
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
  }, [fetchTrigger]);

  if (create) {
    return (
      <>
        <div style={{ paddingTop: 1 }}>
          <FormCreate
            context={props.context}
            type="create"
            create={create}
            read={read}
            edit={edit}
            data={data}
            errors={errors}
            personnel={personnel}
            formChange={formChange}
            handleOwnerChange={handleOwnerChange}
            handleSave={handleSave}
          />
        </div>

        <ApiLoaderComponent status={loading.status} data={loading.data} />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background} />;
  }
}
